// Global packages and components
import { clsx } from 'clsx'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { getProductPageLink } from '~/utils'
import { useNavigate } from '@remix-run/react'

// Types
import type { BrendaHeaderSearchProduct } from '~/types'

interface Props {
  featured?: boolean
  hasFocus?: boolean
  popular?: string[]
  suggestions?: { suggest: TrustedHTML }[]
  products?: BrendaHeaderSearchProduct[]
  onClick: (e: string) => void
  setHasFocus: (e: boolean) => void
}

// Main export
const SearchDropdown = ({
  featured,
  hasFocus,
  popular,
  suggestions,
  products,
  onClick,
  setHasFocus,
}: Props) => {
  const navigate = useNavigate()

  return (
    <div
      className={clsx(
        'absolute left-0 top-0 w-full overflow-y-auto rounded-xl bg-neutral-50 px-5 pb-5 text-left shadow-md',
        {
          hidden: !hasFocus,
          block: hasFocus,
          'pt-28': featured,
          'pt-20': !featured,
        }
      )}
    >
      <div className="max-h-64 overflow-y-auto">
        {!suggestions || suggestions?.length == 0 ? (
          popular &&
          popular?.length > 0 && (
            <div>
              <h5 className="mb-3 font-semibold">Popular searches</h5>
              <ul>
                {popular.map((e, i) => (
                  <li
                    key={i}
                    className="mb-1"
                  >
                    <button
                      className="pointer-events-auto flex w-full items-center hover:bg-gray-100"
                      onMouseDown={() => {
                        setHasFocus(false)
                        onClick(e)
                      }}
                    >
                      <MagnifyingGlassIcon className="mr-1 size-4" />
                      {e}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        ) : (
          <>
            {suggestions && suggestions?.length > 0 && (
              <div className="mb-6">
                <h5 className="mb-4 font-semibold">Suggestions</h5>
                <ul>
                  {suggestions.map((e, i) => (
                    <li
                      key={i}
                      className="mb-1"
                    >
                      <button
                        className="flex w-full items-start justify-start rounded-sm text-left hover:bg-gray-100"
                        onMouseDown={() => {
                          setHasFocus(false)
                          onClick(
                            e.suggest
                              .toString()
                              .replace(/<\/?[^>]+(>|$)/g, '') as string
                          )
                        }}
                      >
                        <MagnifyingGlassIcon className="mr-1 size-5 pt-1" />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: e.suggest,
                          }}
                        />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {products && products?.length > 0 && (
              <div>
                <h5 className="mb-4 font-semibold">Products</h5>
                <ul>
                  {products.slice(0, 6).map((e, i) => (
                    <li
                      key={i}
                      className="mb-2 cursor-pointer"
                    >
                      <button
                        onMouseDown={() => {
                          setHasFocus(false)
                          navigate(
                            getProductPageLink({
                              productType: e.hyraxProductType,
                              stockCode: e.parentProduct.stockCode,
                              slug: e.parentProduct.slug,
                              variant: e?.option?.value,
                            })
                          )
                        }}
                      >
                        <div className="flex items-start gap-4">
                          <div className="relative flex aspect-square w-20 shrink-0 items-center justify-center overflow-hidden rounded-lg bg-white">
                            <div>
                              {e?.images &&
                                e.images?.length > 0 &&
                                e.images[0]?.imageUrl && (
                                  <img
                                    src={e.images[0].imageUrl}
                                    alt={e.name + ' image'}
                                    className="max-h-full max-w-full"
                                  />
                                )}
                            </div>
                          </div>
                          <div className="pt-1 text-left">
                            <h5 className="mb-1">{e.name}</h5>
                            <p className="text-sm font-semibold">
                              {e.hyraxProductType == 'bulk' ? 'Hire' : 'Buy'}
                            </p>
                          </div>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default SearchDropdown
