// Global packages and components
import { clsx } from 'clsx'
import { Form, useSubmit } from '@remix-run/react'
import { useState } from 'react'
import { SearchDropdown } from '~/components'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useSearch } from '~/hooks'

// Types
interface Props {
  featured?: boolean
  buttonText?: string
  placeholder?: string
}

// Main export
const ProductSearch = ({
  featured,
  buttonText,
  placeholder = 'Search...',
}: Props) => {
  const submit = useSubmit()
  const [query, setQuery] = useState<string>('')
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const { popular, products, suggestions } = useSearch({
    query,
  })

  const handleSubmit = (newQuery?: string) => {
    // Update string in input if passed
    if (newQuery) {
      setQuery(newQuery)
    }

    // Create form data object
    const formData = new FormData()
    formData.append('query', newQuery ?? query)

    // Remove active focus from dropdown
    setHasFocus(false)

    // Submit to results route
    submit(formData, { action: '/p', method: 'get' })
  }

  return (
    <div className="relative z-30 -m-2 w-full p-2 md:-m-4 md:p-4">
      <Form
        className="relative z-[3] flex w-full"
        action="/p"
        method="get"
        onSubmit={e => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <input
          name="query"
          type="search"
          value={query}
          autoComplete="off"
          onChange={e => {
            setHasFocus(true)
            setQuery(e.target.value)
          }}
          className={clsx(
            'w-full max-w-full rounded-l-lg border border-neutral-300 p-3 text-sm outline-none',
            {
              'md:rounded-full md:p-6 md:text-xl': featured,
            }
          )}
          placeholder={placeholder}
          onFocus={() => {
            setHasFocus(true)
          }}
          onBlur={() => {
            setHasFocus(false)
          }}
        />
        <button
          className={clsx(
            'flex cursor-pointer items-center rounded-r-lg bg-teal-green p-3 text-white',
            {
              'md:absolute md:right-3 md:top-1/2 md:-translate-y-1/2 md:rounded-full md:px-8':
                featured,
            }
          )}
        >
          {buttonText && (
            <span className="hidden font-semibold md:inline-block">
              {buttonText}
            </span>
          )}
          <MagnifyingGlassIcon
            className={clsx('size-5', {
              'md:hidden': buttonText,
            })}
          />
        </button>
      </Form>
      {((suggestions && suggestions?.length > 0) ||
        (products && products?.length > 0) ||
        (popular && popular?.length > 0)) && (
        <SearchDropdown
          featured={featured}
          hasFocus={hasFocus}
          setHasFocus={setHasFocus}
          popular={popular}
          suggestions={suggestions}
          products={products}
          onClick={handleSubmit}
        />
      )}
    </div>
  )
}

export default ProductSearch
