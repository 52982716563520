// Global packages and functions
import { useState, useEffect } from 'react'
import { useFetcher, useRouteLoaderData } from '@remix-run/react'
import { makeRequest } from '~/utils'
import { action } from '~/routes/api.token'

// Types
import type { BrendaHeaderSearchProduct, RootDataProps } from '~/types'

// Main export
const useSearch = ({ query }: { query: string }) => {
  // Hooks
  const { env } = useRouteLoaderData('root') as RootDataProps
  const fetcherPopular = useFetcher<typeof action>()
  const fetcherSuggested = useFetcher<typeof action>()

  // Local states
  const [loading, setLoading] = useState<boolean>(false)
  const [popular, setPopular] = useState<string[]>([])
  const [suggestions, setSuggestions] = useState<{ suggest: string }[]>([])
  const [products, setProducts] = useState<BrendaHeaderSearchProduct[]>([])

  // Get the token which then fires the get suggested endpoint
  const getSuggestedResults = async () => {
    fetcherSuggested.submit(
      {},
      { method: 'post', encType: 'application/json', action: '/api/token' }
    )
  }

  // Get the token which then fires the get popular endpoint
  const getPopularResults = async () => {
    fetcherPopular.submit(
      {},
      { method: 'post', encType: 'application/json', action: '/api/token' }
    )
  }

  // Clear the suggestion results data
  const clearData = () => {
    setSuggestions([])
    setProducts([])
  }

  // Get the suggested results based off a query string
  const fetchSuggested = async ({
    token,
    query,
  }: {
    token: string
    query: string
  }) => {
    return await makeRequest({
      url: `${env.BRENDA_API_URL}/catalogue/autocomplete`,
      options: {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          suggestions: [
            {
              id: 'suggestedSearchID',
              query: query,
              limit: 5,
            },
          ],
        }),
      },
    })
  }

  // Get the popular results on load
  const fetchPopular = async ({ token }: { token: string }) => {
    return await makeRequest({
      url: `${env.BRENDA_API_URL}/catalogue/autocomplete/popular-searches`,
      options: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    })
  }

  // Get popular results on mount
  useEffect(() => {
    getPopularResults()
  }, [])

  // Get suggested results when query value changes
  useEffect(() => {
    if (query) {
      const queryDelay = setTimeout(() => {
        getSuggestedResults()
      }, 500)

      return () => clearTimeout(queryDelay)
    } else {
      clearData()
    }
  }, [query])

  // Run the popular search function and populate our local state
  useEffect(() => {
    if (fetcherPopular?.data?.token) {
      fetchPopular({
        token: fetcherPopular.data.token,
      })
        .then(data => {
          if (data?.body.popularSearches.length > 0) {
            setPopular(data.body.popularSearches)
          } else {
            setPopular([])
          }
        })
        .catch(error => {
          setPopular([])
          console.error('error', error)
          throw new Error('Failed to get popular searches')
        })
    }
  }, [fetcherPopular?.data])

  // Run the suggested results function and populate our local state
  useEffect(() => {
    if (fetcherSuggested?.state == 'loading') {
      setLoading(true)
    }

    if (fetcherSuggested?.data?.token) {
      fetchSuggested({
        token: fetcherSuggested.data.token,
        query: query,
      })
        .then(data => {
          if (data?.body.suggestionResults[0].suggestions.length > 0) {
            setSuggestions(data.body.suggestionResults[0].suggestions)
          } else {
            setSuggestions([])
          }
          if (data?.body.productResults.length > 0) {
            setProducts(data.body.productResults)
          } else {
            setProducts([])
          }
        })
        .catch(error => {
          console.error('error', error)
          throw new Error('Failed to get products')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [fetcherSuggested?.data])

  return {
    popular,
    suggestions,
    products,
    loading,
  }
}

export default useSearch
